import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  textInput,
  $attrs,
} from '@formkit/inputs'
import { inlineSvg } from '@forms/formkit/sections'

export const searchInputSchema: FormKitTypeDefinition = {
  schema: $attrs(
    {
      'data-collapse': '$isCollapsed',
    },
    outer(
      wrapper(
        label('$label'),
        inner(
          inlineSvg('iconamoon:search-light', '$classes.icon'),
          textInput(),
          {
            $el: 'button',
            attrs: {
              type: 'button',
              disabled: '$disabledClearButton',
              class: '$classes.button',
              onClick: '$handlers.clearValue',
            },
            children: [inlineSvg('iconamoon:close-light', '')],
          },
        ),
      ),
      help('$help'),
      messages(message('$message.value')),
    ),
  ),
  type: 'input',
  family: 'text',
  props: ['collapse'],
  forceTypeProp: 'search',
  features: [addHandlers],
  schemaMemoKey: 'rj3wnl202wg',
}

function addHandlers(node: FormKitNode) {
  node.on('created', () => {
    node.context!.disabledClearButton = !node._value
    node.context!.handlers.clearValue = function (event) {
      node.input('')
      event.target.parentElement.parentElement
        .querySelector('input[type="search"]')
        ?.focus()
    }

    node.context!.isCollapsed = !!node.props.collapse

    if (node.props.collapse && node._value) {
      node.context!.isCollapsed = false
    }

    node.on('input', () => {
      if (node._value) {
        node.context!.isCollapsed = false
        node.context!.disabledClearButton = false
      } else {
        node.context!.isCollapsed = !!node.props.collapse
        node.context!.disabledClearButton = true
      }
    })
  })
}
