<template>
  <div
    :data-accordion="id"
    class="dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'

interface Props {
  id: string
  multiple?: boolean
}

const { id, multiple } = defineProps<Props>()

const openedPanels = ref<string[]>([])
provide('openedPanels', openedPanels)

function togglePanel(key: string) {
  if (multiple) {
    openedPanels.value.includes(key)
      ? (openedPanels.value = openedPanels.value.filter((k) => k !== key))
      : openedPanels.value.push(key)
  } else {
    openedPanels.value = openedPanels.value.includes(key) ? [] : [key]
  }
}

const accordionId = `accordion-${id}`
provide('accordionId', accordionId)

const bus = useEventBus(accordionId)
bus.on((_, p) => {
  togglePanel(p)
})

defineOptions({
  name: 'Accordion',
})
</script>
