<script setup lang="ts">
interface Props {
  type?: AlertType
  icon?: string | null
  minimized?: boolean
  title?: string
  classes?: {
    container?: string | object | []
    image?: string | object | []
    placeholder?: string | object | []
  }
}

const {
  minimized = false,
  title = '',
  type = ALERT_TYPE.INFO,
  icon,
  classes = {},
} = defineProps<Props>()

const { icons } = useDesign()
const isMinimized = ref(minimized)

const cssClasses = computed(() => {
  const { container } = classes
  let themeClassList = ''

  if (type === ALERT_TYPE.INFO) {
    themeClassList = 'bg-system-info text-on-system-info'
  } else if (type === ALERT_TYPE.SUCCESS) {
    themeClassList = 'bg-system-success text-on-success'
  } else if (type === ALERT_TYPE.ERROR) {
    themeClassList = 'bg-system-error text-on-system-error'
  }

  return {
    container:
      container ??
      `rounded px-4 py-2 grid grid-cols-[1.5rem,1fr,1.5rem] gap-2 text-sm ${themeClassList}`,
  }
})

defineOptions({
  name: 'AlertBox',
})
</script>

<template>
  <div :class="cssClasses.container">
    <Icon
      v-if="icon"
      :name="icon ?? icons.INFO_OUTLINE"
      class="size-5 fill-current"
    />

    <slot>
      <p :class="{ 'line-clamp-2': isMinimized }" v-html="title" />
    </slot>

    <button
      v-if="minimized"
      type="button"
      class="flex justify-self-start"
      @click="isMinimized = !isMinimized"
    >
      <Icon
        v-if="minimized"
        :key="isMinimized.toString()"
        :name="isMinimized ? icons.CHEVRON_DOWN : icons.CHEVRON_UP"
        class="size-6 fill-current"
      />
    </button>
  </div>
</template>
