<template>
  <FormKit
    :id="formId"
    v-slot="{ state, value }"
    :value="modelValue"
    type="form"
    preserve
    :classes="classes"
    :plugins="[stepPlugin]"
    :actions="false"
    @input="updateValue"
    @submit="emit('submit', $event)"
  >
    <MultiStepFormProgressBar
      :steps="steps"
      :active-step="activeStep"
      :visited-steps="visitedSteps"
      :disabled="!!state.loading || state.submitted"
      :classes="{
        container: 'py-4 w-full mb-8 px-8',
        indicator: 'h-6 w-6 text-sm',
      }"
      @change="setStep($event)"
    />
    <slot
      :loading="state.loading"
      :submitted="state.submitted"
      :value="value"
      :is-first-step="isFirstStep"
      :is-last-step="isLastStep"
      :next-step="nextStep"
      :prev-step="prevStep"
    />
  </FormKit>
</template>

<script setup lang="ts">
interface Props {
  formId: string
  modelValue: any
  classes: any
}

defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'submit'])
const {
  steps,
  visitedSteps,
  stepPlugin,
  activeStep,
  isFirstStep,
  isLastStep,
  setStep,
  nextStep,
  prevStep,
} = useSteps()

// use provide to avoid passing active step into every step component
provide('activeStep', activeStep)

const updateValue = (newValue: any) => {
  emit('update:modelValue', newValue)
}

defineOptions({
  name: 'MultiStepForm',
})
</script>
