<script setup lang="ts">
import { BUTTON_SIZE, BUTTON_THEME } from '@base/constants/button'
import { MESSAGE_SEVERITY } from '@base/constants/message'
import { TRANSITION_POSITIONS } from '@base/constants/transition-positions'

interface Props {
  message: Message
  timeout?: number
}

const { timeout, message } = withDefaults(defineProps<Props>(), {
  timeout: 8000,
})

const emit = defineEmits(['closed'])
const { icons } = useDesign()
const isVisible = ref(false)
let timeoutId: number | null = null

const icon = computed(() => {
  return message.severity === MESSAGE_SEVERITY.SUCCESS
    ? icons.value.TOAST_SUCCESS
    : message.severity === MESSAGE_SEVERITY.INFO
      ? icons.value.TOAST_INFO
      : icons.value.TOAST_ALERT
})

function onMouseOver() {
  if (timeoutId !== null) {
    clearTimeout(timeoutId)
    timeoutId = null
  }
}

onMounted(() => {
  isVisible.value = true
  timeoutId = window.setTimeout(close, timeout)
})

function onMouseOut() {
  timeoutId = timeoutId ?? window.setTimeout(close, timeout)
}

function close() {
  isVisible.value = false
  emit('closed')
}

const toastRef = ref<HTMLElement | null>(null)
const offset = ref(0)
const startX = ref(0)
const SWIPE_MULTIPLIER = 1.5
const SWIPE_THRESHOLD = 60

function onTouchStart(e: TouchEvent) {
  startX.value = e.touches[0].clientX
  if (timeoutId !== null) {
    clearTimeout(timeoutId)
    timeoutId = null
  }
}

function onTouchMove(e: TouchEvent) {
  const currentX = e.touches[0].clientX
  const diff = currentX - startX.value

  if (diff > 0) {
    offset.value = diff * SWIPE_MULTIPLIER
  }
}

function onTouchEnd() {
  if (offset.value > SWIPE_THRESHOLD) {
    offset.value = window.innerWidth
    setTimeout(close, 150)
  } else {
    offset.value = 0
    timeoutId = window.setTimeout(close, timeout)
  }
}

defineOptions({
  name: 'MessageToast',
})
</script>

<template>
  <TransitionSlide
    :enter-from="TRANSITION_POSITIONS.TOP"
    :enter-to="TRANSITION_POSITIONS.CENTER"
    :leave-to="TRANSITION_POSITIONS.TOP"
    :with-fade="true"
  >
    <PageContainer v-if="isVisible" :default-theme="false" class="!py-0">
      <div
        ref="toastRef"
        data-toast
        class="grid transform touch-pan-x grid-cols-[minmax(0,1fr),2rem] items-center rounded-md p-2 shadow-lg transition-transform duration-200"
        :class="{
          'bg-system-error text-on-system-error':
            message.severity === MESSAGE_SEVERITY.ERROR ||
            message.severity === MESSAGE_SEVERITY.WARNING,
          'bg-system-info text-on-system-info':
            message.severity === MESSAGE_SEVERITY.INFO,
          'bg-system-success text-on-system-success':
            message.severity === MESSAGE_SEVERITY.SUCCESS,
        }"
        :style="{ transform: `translateX(${offset}px)` }"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @mouseover="onMouseOver"
        @mouseout="onMouseOut"
      >
        <div class="flex justify-center space-x-2">
          <Icon :name="icon" class="size-6 shrink-0 fill-current" />
          <div class="no-wrap flex-grow text-base" v-html="message.message" />
        </div>

        <Button
          :icon="icons.CLOSE"
          :theme="BUTTON_THEME.TRANSPARENT"
          :size="BUTTON_SIZE.SM"
          class="hover:bg-dark/20"
          @click="close"
        />
      </div>
    </PageContainer>
  </TransitionSlide>
</template>
