import { useScriptTag } from '@vueuse/core'

export default function useAdyen() {
  const runtimeConfig = useRuntimeConfig()
  const { load } = useScriptTag(
    `https://checkoutshopper-${runtimeConfig.public.ADYEN_ENVIRONMENT}.adyen.com/checkoutshopper/sdk/5.47.0/adyen.js`,
    () => {},
    {
      manual: true,
      attrs: {
        integrity:
          'sha384-kAenwTjBl+LjzL4SR6pnW6g86ECKgokxyNnJBNQHQUajDdTJ+9VSnEQnq2qb/2HL',
        crossorigin: 'anonymous',
      },
    }
  )

  async function loadAdyenSdk({
    clientKey,
    session,
  }: {
    clientKey: string
    session: {
      id: string
      sessionData: string
    }
  }) {
    await load()

    return await AdyenCheckout({
      environment: runtimeConfig.public.ADYEN_ENVIRONMENT ?? 'test',
      clientKey,
      analytics: {
        enabled: false,
      },
      session: {
        id: session.id,
        sessionData: session.sessionData,
      },
    })
  }

  return {
    loadAdyenSdk,
  }
}
