<template>
  <section v-show="activeStep === step" :data-step="step">
    <FormKit type="group" :name="step" :label="label">
      <slot />
    </FormKit>
  </section>
</template>

<script setup lang="ts">
interface Props {
  step: string
  label: string
}

const activeStep = inject('activeStep')

defineProps<Props>()

defineOptions({
  name: 'MultiStepFormStep',
})
</script>
