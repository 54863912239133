import type { CmsBlockCinemasProps } from '@cms/components/cms/block/cinemas.vue'
import type { CmsBlockMoviesProps } from '@cms/components/cms/block/movies.vue'
import type { CmsBlockMovieListProps } from '@cms/components/cms/block/movie-list.vue'
import type { CmsBlockCinemasForMovieProps } from '@cms/components/cms/block/cinemas-for-movie.vue'

export default function useCatalogConfig(
  props:
    | CmsBlockCinemasProps
    | CmsBlockMoviesProps
    | CmsBlockMovieListProps
    | CmsBlockCinemasForMovieProps
) {
  const { id, __typename, showFallbackResults, ...rest } = props

  return {
    dataBlockId: id,
    dataBlockType: __typename,
    catalogProps: computed(() => ({
      ...rest,
      fetchFallbackResults: showFallbackResults,
      urlPrefix: id,
    })),
  }
}
