export const CREDIT_CARD_REGEX = {
  MASTERCARD:
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
} as const

export type CardType = keyof typeof CREDIT_CARD_REGEX | ''

function luhnCheck(cardNumber: string): boolean {
  let sum = 0
  let isEven = false

  // Loop through values starting from the rightmost side
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber[i], 10)

    if (isEven) {
      digit *= 2
      if (digit > 9) {
        digit -= 9
      }
    }

    sum += digit
    isEven = !isEven
  }

  return sum % 10 === 0
}

export default function validateCreditCard(cardNumber: string): {
  isValid: boolean
  type: CardType
} {
  const cleanNumber = cardNumber.replace(/[\s-]/g, '')

  if (CREDIT_CARD_REGEX.MASTERCARD.test(cleanNumber)) {
    return { isValid: luhnCheck(cleanNumber), type: 'MASTERCARD' }
  }

  if (CREDIT_CARD_REGEX.VISA.test(cleanNumber)) {
    return { isValid: luhnCheck(cleanNumber), type: 'VISA' }
  }

  return { isValid: false, type: '' }
}
