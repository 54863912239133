import { useScriptTag } from '@vueuse/core'
import useCart from '@booking/composables/use-cart'
import { ref } from 'vue'

export default async function usePayWithGooglePay() {
  const { cart, checkoutCart } = await useCart()
  const token = ref('')
  const session = ref({
    id: '',
    sessionData: '',
  })

  const { load } = useScriptTag(
    'https://pay.google.com/gp/p/js/pay.js',
    () => {},
    {
      manual: true,
    }
  )

  async function loadGooglePaySdk() {
    await load()
  }

  async function init() {
    const result = await GqlCreateGooglePaySession({
      cartId: cart.value!.id,
    })

    token.value = result.createGooglePaySession.token
    session.value = {
      id: result.createGooglePaySession.sessionId,
      sessionData: result.createGooglePaySession.sessionData,
    }
  }

  async function payWithGooglePay() {
    const result = await GqlCartPayWithGooglePay({
      cartId: cart.value!.id,
      paymentToken: token.value,
    })

    if (result.payWithGooglePay) {
      await checkoutCart()
    }
  }

  return {
    init,
    session,
    token,
    payWithGooglePay,
    loadGooglePaySdk,
  }
}
