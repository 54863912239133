import type { SubscriptionInfo } from '#gql/default'

export async function useSubscriptionInfo() {
  const subscriptionInfos = useState<SubscriptionInfo[]>(
    'subscriptionInfos',
    () => []
  )
  const vouchercode = ref<string>('')

  const { data, error, status, execute } = await useAsyncGql(
    'FetchSubscriptionInfo',
    {
      vouchercode,
    },
    { immediate: false }
  )

  const pending = computed(() => status.value === 'pending')
  const subscription = computed(
    () => data.value?.subscriptionInfo as SubscriptionInfo | null
  )

  async function checkSubscription(code: string) {
    vouchercode.value = code
    await execute()

    if (!subscription.value) {
      throw new Error('No subscription found for this code')
    }

    subscriptionInfos.value.push(subscription.value)
  }

  function clearSubscriptions() {
    subscriptionInfos.value = []
  }

  function removeSubscription(vouchercode: string) {
    subscriptionInfos.value = subscriptionInfos.value.filter(
      (subscription) => subscription.vouchercode !== vouchercode
    )
  }

  return {
    pending,
    error,
    checkSubscription,
    clearSubscriptions,
    removeSubscription,
    subscriptionInfos,
  }
}
