<template>
  <div
    class="absolute left-0 top-0 top-16 z-40 flex w-full flex-col items-center justify-center lg:pr-2"
  >
    <div
      class="lg:container-spacing-horizontal w-full px-2 lg:container lg:mx-auto lg:px-0 xl:max-w-6xl 2xl:max-w-6xl"
    >
      <Toast
        v-for="{ message, id, type } in toasts"
        :key="id"
        :message
        :type
        @closed="removeToast(id!)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { toasts, removeToast } = useToasts()

defineOptions({
  name: 'ToastContainer',
})
</script>
