<script setup lang="ts">
interface Props {
  imageObject: ImageObject
  imageConfig: ImageConfig
  rounded?: RoundedTypes
}

const { rounded = ROUNDED_TYPE.LG } = defineProps<Props>()
const { icons } = useDesign()

defineOptions({
  name: 'CinemaLogo',
})
</script>

<template>
  <ImageWrapper
    :image="imageObject"
    :config="imageConfig"
    :classes="{
      placeholder: {
        'w-full h-auto absolute top-0 aspect-[1/1] object-fill border border-neutral/50': true,
        'rounded-lg': rounded === ROUNDED_TYPE.LG,
        'rounded-full': rounded === ROUNDED_TYPE.FULL,
      },
      image: {
        'w-full h-auto absolute top-0 aspect-[1/1] object-fill border border-neutral/50': true,
        'rounded-lg': rounded === ROUNDED_TYPE.LG,
        'rounded-full': rounded === ROUNDED_TYPE.FULL,
      },
      container: 'aspect-[1/1] relative',
    }"
  >
    <template #default="{ classList }">
      <ImagePlaceholder
        :rounded="rounded"
        :icon="icons.THEATER"
        :class="classList"
      />
    </template>
  </ImageWrapper>
</template>
