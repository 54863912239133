<template>
  <div />
</template>
<script setup lang="ts">
import type { Order, Cinema } from '#gql/default'

interface Props {
  order: Order
  cinema: Cinema
}

const { order } = defineProps<Props>()

const route = useRoute()

const securityKey = route.params.securityKey as string

const cineMotionCinemaIds = [
  '126',
  '250',
  '251',
  '253',
  '254',
  '526',
  '759',
  '778',
  '872',
  '953',
  '1162',
  '1244',
  '1245',
  '1262',
  '2409',
]

if (order.cinema?.id && cineMotionCinemaIds.includes(order.cinema.id)) {
  setMobileBookingOverrides()
}

function setMobileBookingOverrides() {
  setTimeout(() => {
    // Android
    if (typeof androidApp !== 'undefined') {
      androidApp.bookingConfirmed(order.orderNumber, securityKey)
    }

    // IOS
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.iosAppHandler
    ) {
      window.webkit.messageHandlers.iosAppHandler.postMessage(
        JSON.stringify({
          orderNumber: order.orderNumber,
          securityKey,
        })
      )
    }
  }, 10000)
}
</script>
