import type { FetchRaffleEntriesForUserQueryVariables, TrackRaffleEntryMutationVariables } from '#gql/default'

export default function useRaffleEntry() {
  const { backendToken } = useBackendToken()
  const { state } = useUserAuth()
  const hasParticipated = ref(false)

  async function fetchEntry(variables: FetchRaffleEntriesForUserQueryVariables) {
    if (backendToken.value) {
      return
    }

    useGqlToken(state.value.accessToken)
    const { data } = await useAsyncData('FetchRaffleEntriesForUser', () => GqlFetchRaffleEntriesForUser(variables))
    hasParticipated.value = !!data.value?.me?.raffleEntries?.paginatorInfo?.total
  }

  async function trackEntry(variables: TrackRaffleEntryMutationVariables) {
    // Backend Users should not participate
    if (backendToken.value) {
      return true
    }

    const result = await GqlTrackRaffleEntry(variables)
    return result.raffleTrackEntry
  }

  return {
    trackEntry,
    fetchEntry,
    hasParticipated,
  }
}
