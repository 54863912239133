<template>
  <div
    v-if="messages.length"
    class="fixed left-0 right-0 top-[calc(var(--header-height)_+_0.5rem)] z-50 space-y-1.5"
  >
    <Message v-for="message in messages" :key="message.id" :message="message" />
  </div>
</template>

<script lang="ts" setup>
const { messages, add } = useMessage()
const route = useRoute()
const router = useRouter()
const { ct, fetchCinemaStrings } = useCinemaStrings()

watch(
  () => route.fullPath,
  async () => {
    const hasError = !!route.query.error || !!route.query['error-desc']
    if (hasError) {
      // try to fetch cinema strings for error message and description
      await fetchCinemaStrings({
        keys: [
          route.query.error as string,
          route.query['error-desc'] as string,
        ],
      })

      add({
        title: ct(route.query.error as string),
        message: ct(route.query['error-desc'] as string),
        type: MESSAGE_TYPE.MODAL,
        severity: MESSAGE_SEVERITY.ERROR,
      })

      // Only replace the route if necessary to avoid unnecessary history entries.
      const { error, 'error-desc': errorDesc, ...restQuery } = route.query
      if (error || errorDesc) {
        router.replace({ query: restQuery })
      }
    }
  },
  { immediate: true }
)

defineOptions({
  name: 'MessageController',
})
</script>
