<template>
  <Teleport to="#checkout">
    <div :id="containerId" />
  </Teleport>
</template>

<script setup lang="ts">
import useAdyen from '@booking/composables/use-adyen'
import usePayWithGooglePay from '@booking/composables/use-pay-with-google-pay'
import type { GooglePayPaymentMethod } from '#gql/default'

interface Props {
  formId: string
  paymentMethod: GooglePayPaymentMethod
}

const { paymentMethod } = defineProps<Props>()
const containerId = useId()

const { loadGooglePaySdk, session, init, payWithGooglePay } =
  await usePayWithGooglePay()
const { loadAdyenSdk } = await useAdyen()

try {
  await init()
  await loadGooglePaySdk()

  const checkout = await loadAdyenSdk({
    clientKey: paymentMethod.clientKey,
    session: session.value,
  })

  const googlePayComponent = checkout.create('googlepay', {
    configuration: {
      merchantId: paymentMethod.merchantId,
      merchantName: paymentMethod.merchantName,
      gatewayMerchantId: paymentMethod.gatewayMerchantId,
    },
    buttonType: 'buy',
    buttonColor: 'white',
    buttonLocale: 'de_DE',
    buttonSizeMode: 'fill',
    emailRequired: false,
    shippingAddressRequired: false,
    shippingOptionRequired: false,
    billingAddressRequired: false,
    onAuthorized: async () => {
      await payWithGooglePay()
    },
  })

  googlePayComponent.isAvailable().then(() => {
    googlePayComponent.mount(`#${containerId}`)
  })
} catch (error) {
  console.error(error)
}

defineOptions({
  name: 'PaymentTypeGooglePay',
})
</script>
