<template>
  <nav
    aria-label="Progress"
    :class="[
      cssClasses.container,
      { 'pointer-events-none opacity-30': disabled },
    ]"
  >
    <div class="relative">
      <div class="absolute top-1/2 h-[2px] w-full -translate-y-1/2">
        <div class="bg-neutral absolute left-0 z-10 h-full w-full" />
        <div
          class="bg-primary absolute left-0 z-20 h-full transition-all"
          :style="`width: ${percentCompleted}%`"
        />
      </div>

      <ol role="list" class="relative z-20 flex w-full justify-between">
        <li v-for="(step, stepName, i) in steps" :key="stepName">
          <MultiStepFormProgressStep
            :step="step"
            :valid="step.valid"
            :active="activeStep === stepName"
            :visited="visitedSteps.has(stepName)"
            :upcoming="upcomingStep === stepName"
            :index="i + 1"
            :classes="{
              indicator: cssClasses.indicator,
              label: cssClasses.label,
            }"
            @click="emit('change', stepName)"
          />
        </li>
      </ol>
    </div>
  </nav>
</template>

<script lang="ts" setup>
interface Step {
  blockingCount: number
  errorCount: number
  label: string
  name: string
  valid: boolean
}

interface Props {
  steps: Record<string, Step>
  visitedSteps: Set<string>
  activeStep: string
  disabled: boolean
  classes?: {
    container?: string | object | []
    indicator?: string | object | []
    label?: string | object | []
    button?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => ({}),
})
const emit = defineEmits(['change'])

defineOptions({
  name: 'MultiStepFormProgressBar',
})

const upcomingStep = computed(() => {
  const current = Object.keys(props.steps).indexOf(props.activeStep)
  return Object.keys(props.steps)[current + 1]
})

const cssClasses = computed(() => {
  const { indicator, label, button, container } = props.classes

  return {
    indicator: indicator ?? 'h-6 w-6 sm:h-8 sm:w-8 text-sm',
    label: label ?? 'text-sm w-20 pt-1',
    button: button ?? '',
    container: container ?? 'w-full mb-8 px-8',
  }
})

const percentCompleted = computed(() => {
  const total = Object.keys(props.steps).length - 1
  const completed = Math.max(props.visitedSteps.size)

  return Math.min((completed / total) * 100, 100)
})
</script>
