<script lang="ts" setup>
const panelId = inject<string>('panelId', '')
const accordionId = inject<string>('accordionId', '')
const isOpen = inject<boolean>('isOpen', false)

const bus = useEventBus(accordionId)
const { icons } = useDesign()

function toggle() {
  bus.emit('toggle', panelId)
}

defineOptions({
  name: 'AccordionPanelHeader',
})
</script>

<template>
  <div class="flex items-center justify-end space-x-2 py-2" @click="toggle">
    <slot />
    <Button
      :theme="BUTTON_THEME.TERTIARY"
      :size="BUTTON_SIZE.SM"
      :icon="icons.CHEVRON_DOWN"
      :class="{ 'rotate-180 transform': isOpen }"
    />
  </div>
</template>
