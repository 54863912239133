import useBackendToken from '@account/composables/use-backend-token'

export default defineNuxtRouteMiddleware((to) => {
  // Only run middleware for admin routes
  if (!to.path.startsWith('/admin')) {
    return
  }

  const { backendToken } = useBackendToken()

  // If no backend token, throw error
  if (!backendToken.value) {
    showError({
      statusCode: 403,
      statusMessage: 'accessDenied',
    })
  }
})
