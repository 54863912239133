import type { CustomisedVoucherProduct } from '@voucher-shop/types/voucher-shop'
import type {
  CartVoucherProductInput,
  Cart,
  CartType,
  Cinema,
} from '#gql/default'
import { CART_TYPE } from '@booking/constants/cart'

interface AddToCartPayload {
  quantity: number
  selectedAmount: number
  voucherDesignId: string
  cartVoucherId?: string
  voucherProduct: CustomisedVoucherProduct
  locale: string
  onSuccess?: () => void
  onError?: () => void
}

export default async function useVoucherCart({ cinema }: { cinema?: Cinema }) {
  const { add: addMessage } = useMessage()
  const {
    cart,
    fetchCart,
    ensureCart,
    ensureCartStrings,
    pending,
    getMessage,
  } = await useCart()

  await ensureCartStrings({ cinema })

  async function getCartVoucher(cartVoucherId: string) {
    pending.value = true
    try {
      const cart = await fetchCart()
      return cart?.voucherProducts.find(
        ({ id }: { id: string }) => id === cartVoucherId,
      )
    } finally {
      pending.value = false
    }
  }

  async function patchVoucherProducts(
    voucherProducts: CartVoucherProductInput[],
  ): Promise<Cart> {
    pending.value = true
    try {
      let cartId = cart.value?.id

      if (!cartId) {
        const { id } = await ensureCart({
          cinema,
          type: CART_TYPE.VOUCHER as CartType,
        })
        cartId = id
      }

      // Clean customisations before sending to API
      const cleanedVoucherProducts = voucherProducts.map((product) => ({
        ...product,
        customisations: product.customisations?.map((customisation) => {
          const cleaned: Record<string, any> = {}
          Object.entries(customisation).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
              cleaned[key] = value
            }
          })
          return cleaned
        }),
      }))

      const result = await GqlCartPatchVoucherProducts({
        cartId,
        voucherProducts: cleanedVoucherProducts,
      })

      const updatedCart = result.cartPatchVoucherProducts as Cart
      cart.value = updatedCart

      return updatedCart
    } catch (error) {
      throw new Error(error as string)
    } finally {
      pending.value = false
    }
  }

  async function addToCart(payload: AddToCartPayload) {
    const {
      quantity,
      selectedAmount,
      voucherDesignId,
      cartVoucherId,
      voucherProduct,
      locale,
      onSuccess,
      onError,
    } = payload

    try {
      // Get previous quantity if it's an update
      let previousQuantity
      if (cartVoucherId) {
        const existingItem = await getCartVoucher(cartVoucherId)
        previousQuantity = existingItem?.quantity
      }

      await patchVoucherProducts([
        {
          id: cartVoucherId,
          voucherProductId: voucherProduct.id,
          customisations: voucherProduct.customisations,
          voucherDesignId,
          locale,
          quantity,
          selectedAmount,
        },
      ])

      addMessage({
        message: getMessage({
          newQuantity: quantity,
          previousQuantity,
          cartItemId: cartVoucherId,
          productName: voucherProduct.name,
        }),
        severity: MESSAGE_SEVERITY.SUCCESS,
        type: MESSAGE_TYPE.TOAST,
      })
      onSuccess?.()
    } catch {
      addMessage({
        message: getMessage(),
        severity: MESSAGE_SEVERITY.ERROR,
        type: MESSAGE_TYPE.TOAST,
      })
      onError?.()
    }
  }

  return {
    cart,
    fetchCart,
    patchVoucherProducts,
    getCartVoucher,
    addToCart,
    pending,
  }
}
