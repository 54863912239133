<template>
  <ImageWrapper
    :image="imageObject"
    :config="imageConfig"
    :classes="{
      container: [containerClassList, maxHeightClassList].join(' '),
      image: [imageClassList, maxHeightClassList, alignmentClassList].join(' '),
      placeholder: placeholderClassList,
    }"
  >
    <slot />
  </ImageWrapper>
</template>

<script setup lang="ts">
interface Props {
  imageObject: ImageObject
  imageConfig: ImageConfig
  alignment?: ImageClip['TOP'] | ImageClip['CENTER'] | ImageClip['BOTTOM']
  containerClassList?: string
  imageClassList?: string
  maxHeightClassList?: string
  placeholderClassList?: string
}

const props = withDefaults(defineProps<Props>(), {
  alignment: IMAGE_CLIP.CENTER,
  imageClassList: 'object-cover h-full w-full',
  maxHeightClassList: 'max-h-[300px] sm:max-h-[450px]',
  containerClassList: 'relative h-full w-full',
  placeholderClassList: 'h-[300px] sm:h-[450px]',
})

const alignmentClassList = computed(() => {
  if (props.alignment === IMAGE_CLIP.TOP) {
    return 'object-top'
  } else if (props.alignment === IMAGE_CLIP.BOTTOM) {
    return 'object-bottom'
  } else {
    return 'object-center'
  }
})

defineOptions({
  name: 'HeroImage',
})
</script>
