import type { FormKitNode } from '@formkit/core'

export default function useSteps() {
  const activeStep = ref('')
  const steps = reactive<{ [key: string]: any }>({})
  const visitedSteps = ref(new Set())
  const isFirstStep = computed(() => activeStep.value === Object.keys(steps)[0])
  const isLastStep = computed(
    () => activeStep.value === Object.keys(steps)[Object.keys(steps).length - 1]
  )

  watch(activeStep, (newStep, oldStep) => {
    if (newStep !== oldStep && oldStep && !visitedSteps.value.has(oldStep)) {
      visitedSteps.value.add(oldStep)
    }
  })

  function nextStep() {
    const stepNames = Object.keys(steps)
    const currentIndex = stepNames.indexOf(activeStep.value)

    setStep(stepNames[currentIndex + 1])
  }

  function prevStep() {
    const stepNames = Object.keys(steps)
    const currentIndex = stepNames.indexOf(activeStep.value)

    setStep(stepNames[currentIndex - 1])
  }

  function setStep(stepName: string) {
    activeStep.value = stepName
  }

  const stepPlugin = (node: FormKitNode) => {
    if (node.props.type === 'group') {
      steps[node.name] = steps[node.name] || {}

      node.on('created', () => {
        steps[node.name].valid = toRef(node.context.state, 'valid')
        steps[node.name].label = node.context.label
      })

      node.on('count:errors', ({ payload: count }: { payload: number }) => {
        steps[node.name].errorCount = count
      })

      node.on('count:blocking', ({ payload: count }: { payload: number }) => {
        steps[node.name].blockingCount = count
      })

      // set the active tab to the 1st tab
      if (activeStep.value === '') {
        activeStep.value = node.name
      }

      return false
    }
  }

  return {
    activeStep,
    visitedSteps,
    steps,
    stepPlugin,
    setStep,
    nextStep,
    prevStep,
    isLastStep,
    isFirstStep,
  }
}
