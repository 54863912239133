<template>
  <div
    class="grid w-full animate-pulse grid-cols-2 gap-x-2 gap-y-1 py-3 sm:grid-cols-[10rem,1fr] md:grid-cols-[10rem,1fr,auto,8rem]"
  >
    <div class="flex items-center space-x-2">
      <div class="h-4 w-20 rounded-lg" :class="cssClasses.color" />
      <div class="h-4 w-10 rounded-lg" :class="cssClasses.color" />
    </div>
    <div
      class="col-span-2 col-start-1 flex flex-wrap items-center space-x-2 pt-1 sm:col-span-1 md:py-0"
    >
      <!-- words -->
      <div class="my-1 h-4 w-8 rounded-lg" :class="cssClasses.color" />
      <div class="my-1 h-4 w-20 rounded-lg" :class="cssClasses.color" />
      <div class="my-1 h-4 w-12 rounded-lg" :class="cssClasses.color" />
      <div class="my-1 w-2" />
      <!-- flags -->
      <div class="my-1 h-5 w-12 rounded-lg" :class="cssClasses.color" />
      <div class="my-1 h-5 w-8 rounded-lg" :class="cssClasses.color" />
    </div>
    <div class="flex items-center space-x-3 md:px-2">
      <div class="m-2 h-6 w-6 rounded-full" :class="cssClasses.color" />
      <div class="m-2 h-6 w-6 rounded-full" :class="cssClasses.color" />
    </div>
    <div
      class="col-start-2 row-start-3 flex justify-end sm:row-start-2 md:col-start-4 md:row-start-1"
    >
      <div class="h-10 w-28 rounded-md" :class="cssClasses.color" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'ShowsListItemSkeleton',
})
</script>
