<template>
  <slot
    :pending="pending"
    :movies="movies"
    :fetch-more="fetchMore"
    :has-more-pages="hasMorePages"
    :has-previous-pages="hasPreviousPages"
    :has-filters-applied="hasFiltersApplied"
  />
</template>

<script setup lang="ts">
import type {
  FetchShowGroupsForMovieListQueryVariables,
  ShowGroup,
  LinkParam,
} from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchShowGroupsForMovieListQueryVariables
  linkParams: LinkParam[]
}

const COMPONENT_NAME = 'MovieListDataProviderShowGroups'
const { getMovieLink } = useGetMovieLink()
const props = defineProps<Props>()
const showGroups = ref()
const page = ref(1)

const { data, pending, execute, refresh } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () =>
    GqlFetchShowGroupsForMovieList({ ...props.fetchParams, page: page.value }),
  {
    immediate: false,
  }
)
await execute()

showGroups.value = data.value?.showGroups?.data ?? []

const hasMorePages = computed(
  () => data.value?.showGroups?.paginatorInfo?.hasMorePages
)
const hasPreviousPages = computed(() => page.value > 1)
const hasFiltersApplied = computed(
  () =>
    !!(
      props.fetchParams.genres ||
      props.fetchParams.contentRatings ||
      props.fetchParams.periods ||
      props.fetchParams.flags
    )
)

const movies = computed(() =>
  showGroups.value?.map((group: ShowGroup) => ({
    ...group.movie,
    poster: getImage(group.movie, 'thumbnailImage', group.name),
    detailLink: getMovieLink({
      movieSlug: group.urlSlug,
      linkParams: props.linkParams,
    }),
  }))
)

async function fetchMore() {
  page.value++
  await refresh()
  showGroups.value.push(...(data.value?.showGroups?.data ?? []))
}

defineOptions({
  name: COMPONENT_NAME,
})
</script>
