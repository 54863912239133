<template>
  <div
    class="grid grid-rows-[auto,0fr] transition-[grid-template-rows] ease-in-out [&>div]:overflow-hidden"
    :class="{ 'grid-rows-[auto,1fr]': isOpen }"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  id: string
  open?: boolean
}

const { id } = defineProps<Props>()
const openedPanels = inject<Ref<string[]>>('openedPanels', ref([]))
const isOpen = computed(() => openedPanels.value?.includes(id))

provide('panelId', id)
provide('isOpen', isOpen)

defineOptions({
  name: 'AccordionPanel',
})
</script>
