<script setup lang="ts">
import type { Flag, LinkParam, Show } from '#gql/default'

interface Props {
  show: Show & { flags: (Flag & { isHidden?: boolean })[] }
  linkParams?: LinkParam[]
  bookingModal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  bookingModal: false,
  linkParams: () => [],
})

const localeRoute = useLocaleRoute()

const detailLink = computed(() => {
  const query = props.linkParams.reduce(
    (acc, { key, value }) => {
      acc[key] = value
      return acc
    },
    {} as Record<string, string>,
  )

  return localeRoute({
    name: ROUTE_NAMES.SHOW,
    params: {
      movieSlug: props.show.showGroup?.urlSlug,
      showSlug: props.show.urlSlug,
    },
    query: {
      ...query,
      cinemaId: props.show.cinema?.cid,
    },
  })
})

const {
  isBookingModalVisible,
  isInfoModalVisible,
  isExternal,
  isMidnightShow,
  hasCinemaSpecificFlags,
  isSaleActive,
  handleClick,
  bookingLink,
  flags,
  cssClasses,
} = useShowLink({
  show: props.show,
  detailLink,
  bookingModal: props.bookingModal,
})

defineOptions({
  name: 'ShowLink',
})
</script>

<template>
  <a
    :href="bookingLink"
    :target="isExternal ? LINK_TARGET.BLANK : LINK_TARGET.SELF"
    :rel="isExternal ? `${LINK_REL.NOOPENER} ${LINK_REL.NOREFERRER}` : ''"
    :class="cssClasses"
    data-show-link
    @click.prevent="handleClick"
  >
    <slot>
      <div
        class="text-on-neutral flex h-11 items-center justify-center"
        data-show-link-time
        v-text="show.beginning.substring(11, 16)"
      />

      <ShowLinkFlags v-if="flags.length" :flags="flags" />
    </slot>

    <LazyShowBookingModal
      v-if="isBookingModalVisible"
      :show
      @close="isBookingModalVisible = false"
    />

    <LazyShowInfoModal
      v-if="isInfoModalVisible"
      :show
      :booking-link
      :is-external
      :is-sale-in-active="!isSaleActive"
      :is-midnight-show
      :has-cinema-specific-flags
      @close="isInfoModalVisible = false"
    />
  </a>
</template>
