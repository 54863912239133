<template>
  <slot :slides :pending />
</template>

<script lang="ts" setup>
import type {
  FetchShowGroupsForMovieCarouselQueryVariables,
  LinkParam,
} from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchShowGroupsForMovieCarouselQueryVariables
  linkParams: LinkParam[]
}

const COMPONENT_NAME = 'MovieCarouselDataProviderShowGroups'
const props = defineProps<Props>()

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchShowGroupsForMovieCarousel(props.fetchParams),
  {
    immediate: false,
  }
)
await execute()

const slides = computed(() => {
  const groups = new Set()

  const filteredGroups = data.value?.showGroups?.data.filter(({ urlSlug }) => {
    const duplicate = groups.has(urlSlug)
    groups.add(urlSlug)
    return !duplicate
  })

  return filteredGroups?.map((group) => ({
    type: CAROUSEL_SLIDE_TYPE.MOVIE,
    urlSlug: group.urlSlug,
    ...group.movie,
  }))
})

defineOptions({
  name: COMPONENT_NAME,
})
</script>
