import type { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  boxInner,
  help,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  decorator,
  box,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  radios,
  $if,
  $extend,
  $attrs,
} from '@formkit/inputs'
import { inlineSvg } from '@forms/formkit/sections'

export const radioInputSchema: FormKitTypeDefinition = {
  schema: $attrs(
    {
      'data-tile': '$tile !== undefined',
    },
    outer(
      $if(
        '$options == undefined',
        boxWrapper(
          boxInner(
            prefix(),
            box(),
            decorator(
              inlineSvg('fluent-mdl2:radio-bullet', '$classes.decoratorIcon'),
            ),
          ),
          $extend(boxLabel('$label'), {
            if: '$label',
          }),
          suffix(),
        ),
        fieldset(
          legend('$label'),
          help('$help'),
          boxOptions(
            $attrs(
              {
                'data-checked': '$fns.isChecked($option.value)',
              },
              boxOption(
                boxWrapper(
                  boxInner(
                    prefix(),
                    $extend(box(), {
                      bind: '$option.attrs',
                      attrs: {
                        id: '$option.attrs.id',
                        value: '$option.value',
                        checked: '$fns.isChecked($option.value)',
                      },
                    }),
                    decorator(
                      inlineSvg(
                        'fluent-mdl2:radio-bullet',
                        '$classes.decoratorIcon',
                      ),
                    ),
                  ),
                  $extend(boxLabel('$option.label'), {
                    if: '$option.label',
                  }),
                ),
                suffix(),
              ),
            ),
          ),
        ),
      ),
      $if('$options == undefined && $help', help('$help')),
      messages(message('$message.value')),
    ),
  ),
  type: 'input',
  family: 'box',
  props: ['options', 'onValue', 'offValue', 'optionsLoader', 'tile'],
  forceTypeProp: 'radio',
  features: [options, radios],
}
