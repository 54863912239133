<template>
  <transition-group
    :enter-from-class="enterFromClasses"
    enter-active-class="ease-in-out duration-300"
    :enter-to-class="enterToClasses"
    leave-from-class="opacity-100"
    leave-active-class="ease-in-out duration-300"
    :leave-to-class="leaveToClasses"
    appear
  >
    <slot />
  </transition-group>
</template>

<script setup lang="ts">
interface Props {
  enterFrom: TransitionPositions
  enterTo: TransitionPositions
  leaveTo: TransitionPositions
  withFade?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withFade: false,
})

defineOptions({
  name: 'TransitionSlideGroup',
})

const { enterFromClasses, enterToClasses, leaveToClasses } =
  useTransitionPositions({
    enterFrom: props.enterFrom,
    enterTo: props.enterTo,
    leaveTo: props.leaveTo,
    withFade: props.withFade,
  })
</script>
