<template>
  <slot :pending :shows :shows-grouped-by-day :shows-grouped-by-cinema />
</template>

<script lang="ts" setup>
import type {
  FetchShowGroupsForShowsListQueryVariables,
  LinkParam,
} from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchShowGroupsForShowsListQueryVariables
  linkParams: LinkParam[]
}

const COMPONENT_NAME = 'ShowsListDataProviderShowGroups'
const props = defineProps<Props>()

const { data, pending, execute, refresh } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchShowGroupsForShowsList(props.fetchParams),
  {
    immediate: false,
  }
)
await execute()

const shows = computed(() => {
  if (!data.value?.showGroups?.data) {
    return []
  }

  return data.value.showGroups.data
    .flatMap(
      (showGroup) =>
        showGroup.shows?.data?.map((show) => ({
          ...show,
          showGroup: {
            uuid: showGroup.uuid,
            urlSlug: showGroup.urlSlug,
            movie: showGroup.movie,
          },
        })) || []
    )
    .sort(
      (a, b) =>
        new Date(a?.beginning || 0).getTime() -
        new Date(b?.beginning || 0).getTime()
    )
    .filter((show) => new Date(show.endsale) > new Date())
})

const showsGroupedByDay = computed(() =>
  groupObjectsByKeyInto(shows.value, 'isoDate', 'isoDate', 'shows')
)

const showsGroupedByCinema = computed(() =>
  groupObjectsByKeyInto(shows.value, 'cinema', 'cinema.id', 'shows')
)

watch(
  () => props.fetchParams,
  async () => {
    await refresh()
  },
  {
    deep: true,
  }
)

defineOptions({
  name: COMPONENT_NAME,
})
</script>
