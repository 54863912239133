<template>
  <Paginator :paginator-info :next :prev :url-prefix>
    <div
      data-city-list
      class="@lg:grid-cols-3 @xl:grid-cols-4 @2xl:grid-cols-5 grid grid-cols-2 gap-2"
    >
      <CityLink
        v-for="city in cities"
        :key="city.id"
        :city
        class="hover:underline"
      >
        <div v-text="city.name" />
        <div
          v-if="city.postcodes"
          class="text-sm"
          v-text="formatPostCodes(city)"
        />
      </CityLink>
    </div>
  </Paginator>
</template>

<script lang="ts" setup>
import type { FetchCitiesQueryVariables } from '#gql/default'

interface Props {
  urlPrefix?: string
  fetchParams: FetchCitiesQueryVariables
}

const { urlPrefix } = defineProps<Props>()

const { cities, paginatorInfo, next, prev } = await useCities2({
  fetchOperation: 'FetchCities',
  preselected: {},
  urlPrefix,
})

defineOptions({
  name: 'CityList',
})
</script>
