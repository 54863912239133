import useUserAuth from '../composables/use-user-auth'

export default defineNuxtRouteMiddleware(async (to, _) => {
  const { state } = useUserAuth()

  if (import.meta.server) {
    const cookieHeader = useRequestHeaders(['cookie'])
    const cookies = cookieHeader?.cookie
      ?.split(';')
      .reduce((acc: { [key: string]: string }, cookie) => {
        const [key, value] = cookie.split('=').map((c) => c.trim())
        acc[key] = decodeURIComponent(value)
        return acc
      }, {})

    if (cookies?.[ACCOUNT_ACCESS_TOKEN_COOKIE_NAME]) {
      state.value.accessToken = cookies[ACCOUNT_ACCESS_TOKEN_COOKIE_NAME]
    }

    if (cookies?.[ACCOUNT_REFRESH_TOKEN_COOKIE_NAME]) {
      state.value.refreshToken = cookies[ACCOUNT_REFRESH_TOKEN_COOKIE_NAME]
    }
  }

  if (import.meta.client) {
    const accessToken = useCookie(ACCOUNT_ACCESS_TOKEN_COOKIE_NAME)
    const refreshToken = useCookie(ACCOUNT_REFRESH_TOKEN_COOKIE_NAME)

    state.value.accessToken = accessToken.value ?? ''
    state.value.refreshToken = refreshToken.value ?? ''
  }
})
