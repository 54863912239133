import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  boxInner,
  help,
  boxHelp,
  messages,
  message,
  fieldset,
  decorator,
  box,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  checkboxes,
  $if,
  $attrs,
  $extend,
} from '@formkit/inputs'
import { inlineSvg } from '@forms/formkit/sections'

export const checkboxInputSchema: FormKitTypeDefinition = {
  schema: $attrs(
    {
      'data-multiple': '$options !== undefined',
      'data-toggle': '$toggle',
    },
    outer(
      $if(
        '$options == undefined',
        boxWrapper(
          boxInner(
            box(),
            decorator(inlineSvg('$decoratorIcon', '$classes.decoratorIcon')),
          ),
          $extend(boxLabel('$label'), {
            if: '$label',
          }),
        ),
        fieldset(
          legend('$label'),
          boxOptions(
            boxOption(
              boxWrapper(
                boxInner(
                  $extend(box(), {
                    bind: '$option.attrs',
                    attrs: {
                      id: '$option.attrs.id',
                      value: '$option.value',
                      checked: '$fns.isChecked($option.value)',
                    },
                  }),
                  decorator(
                    inlineSvg('$decoratorIcon', '$classes.decoratorIcon'),
                  ),
                ),
                $extend(boxLabel('$option.label'), {
                  if: '$option.label',
                }),
              ),
              boxHelp('$option.help'),
            ),
          ),
        ),
      ),
      help('$help'),
      messages(message('$message.value')),
    ),
  ),
  type: 'input',
  family: 'box',
  props: ['options', 'onValue', 'offValue', 'optionsLoader', 'toggle'],
  forceTypeProp: 'checkbox',
  features: [options, checkboxes, addHandlers],
  schemaMemoKey: 'pi7n0fh6lm',
}

function addHandlers(node: FormKitNode) {
  node.on('created', () => {
    node.context!.toggle = node.props.toggle
    node.context!.decoratorIcon = node.props.toggle
      ? 'fluent-mdl2:radio-bullet'
      : 'fluent:checkmark-20-filled'
  })
}
