<template>
  <div class="relative grid grid-cols-[12rem,1fr] gap-4">
    <div class="col-span-2">
      <div class="h-4 w-72" :class="[cssClasses.color, cssClasses.text]" />
    </div>

    <div class="relative aspect-[2/3]">
      <div class="rounded-lg" :class="[cssClasses.color, cssClasses.image]" />
    </div>

    <div class="space-y-4">
      <div class="w-24" :class="[cssClasses.color, cssClasses.text]" />
      <div class="w-12" :class="[cssClasses.color, cssClasses.text]" />
      <div class="w-24" :class="[cssClasses.color, cssClasses.text]" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'ShowGroupListItemSkeleton',
})
</script>
