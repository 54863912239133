// https://github.com/tailwindlabs/tailwindcss-container-queries
export const TAILWIND_CONTAINER_QUERY = {
  SIZE_XS: 320,
  SIZE_SM: 384,
  SIZE_MD: 448,
  SIZE_LG: 512,
  SIZE_XL: 576,
  SIZE_2XL: 672,
  SIZE_3XL: 768,
  SIZE_4XL: 896,
  SIZE_5XL: 1024,
  SIZE_6XL: 1152,
  SIZE_7XL: 1280,
} as const

export const TAILWIND_CONTAINER_BREAKPOINT = {
  BREAKPOINT_XS: 'xs',
  BREAKPOINT_SM: 'sm',
  BREAKPOINT_MD: 'md',
  BREAKPOINT_LG: 'lg',
  BREAKPOINT_XL: 'xl',
  BREAKPOINT_2XL: '2xl',
  BREAKPOINT_3XL: '3xl',
  BREAKPOINT_4XL: '4xl',
  BREAKPOINT_5XL: '5xl',
  BREAKPOINT_6XL: '6xl',
  BREAKPOINT_7XL: '7xl',
} as const
