<template>
  <img
    v-if="imageData"
    :src="imageData"
    alt="Voucher Preview"
    @load="$emit('load')"
  />
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
  voucherProductId: string
  customisation?: string
  selectedAmount: number
  voucherDesignId: string
}

defineEmits(['load'])

const {
  cinema,
  voucherProductId,
  customisation,
  selectedAmount,
  voucherDesignId,
} = defineProps<Props>()

const { locale } = useI18n()

const imageData = ref('')

const result = await GqlPreviewVoucher({
  cinemaId: cinema?.id,
  voucherProductId,
  voucherDesignId,
  selectedAmount,
  selectedLocale: locale.value,
  customisation,
})

if (result?.previewVoucher.content) {
  imageData.value = `data:image/webp;base64,${result.previewVoucher.content}`
}

defineOptions({
  name: 'VoucherProductPreviewImage',
})
</script>
