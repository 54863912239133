<script setup lang="ts">
interface Props {
  imageConfig: ImageConfig
  image: ImageObject
  classes?: {
    container?: string
  }
}

defineProps<Props>()

const { icons } = useDesign()

defineOptions({
  name: 'CarouselImage',
})
</script>

<template>
  <ImageWrapper
    :image="image"
    :config="imageConfig"
    :classes="{
      image: 'absolute top-0 object-cover w-full h-auto',
      container: 'w-full h-auto relative overflow-hidden aspect-[2/1]',
      placeholder: 'absolute top-0 object-cover w-full h-auto',
    }"
  >
    <template #default="{ classList }">
      <ImagePlaceholder :class="classList ?? ''" :icon="icons.GALLERY_IMAGE" />
    </template>
  </ImageWrapper>
</template>
