<script setup lang="ts">
import type { City } from '#gql/default'

interface Props {
  currentCity?: Omit<City, 'cinemas' | 'postcode' | 'postcodes'>
  useGeoLocation: boolean
  urlPrefix?: string
}

const { urlPrefix, useGeoLocation } = defineProps<Props>()
const emit = defineEmits(['select'])
defineOptions({
  name: 'FiltersGeoLocation',
})

const { icons } = useDesign()
const { coords, error, pause, resume } = useGeolocation({ immediate: false })
const { city } = await useCity({
  urlPrefix: urlPrefix,
})
const { cities, fetchCities } = useCities({
  key: 'fetch-cities-by-geo-location',
})

if (!useGeoLocation) {
  pause()
}

const geoCity = computed(() => cities.value?.[0] ?? null)
const isGeoCitySelected = computed(
  () => !!(geoCity.value && city.value?.urlSlug === geoCity.value?.urlSlug),
)

async function handleClick() {
  resume()

  if (!geoCity.value) {
    await fetchCityByLocation()
  }

  emit('select', geoCity.value)
}

async function fetchCityByLocation() {
  await fetchCities({
    first: 1,
    page: 1,
    proximity: {
      location: {
        latitude: coords.value?.latitude,
        longitude: coords.value?.longitude,
      },
    },
  })
}

watch(coords, (oldCoords, newCoords) => {
  if (!newCoords || JSON.stringify(oldCoords) === JSON.stringify(newCoords)) {
    return
  }

  fetchCityByLocation()
})

watch(geoCity, () => {
  if (geoCity.value && !city.value) {
    emit('select', geoCity.value)
  }
})
</script>

<template>
  <Button
    v-if="coords && !error"
    :key="isGeoCitySelected.toString()"
    :theme="BUTTON_THEME.TRANSPARENT"
    :icon="isGeoCitySelected ? icons.GPS_FILL : icons.GPS_EMPTY"
    @click="handleClick"
  />
</template>
