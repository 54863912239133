import { useEventBus } from '@vueuse/core'
import { useRouteQuery } from '@vueuse/router'
import { submitForm } from '@formkit/core'

export default function useFiltersForm({
  urlPrefix,
  filterName,
  resetFilters,
}: {
  urlPrefix?: string
  filterName: UrlFilter
  resetFilters?: UrlFilter[]
}) {
  const route = useRoute()
  const router = useRouter()

  const formData = ref({ [filterName]: null })
  const formId = [urlPrefix, filterName, 'filter-form']
    .filter(Boolean)
    .join('-')

  const isOpen = ref(false)
  const isSubmitted = ref(false)
  const isEmpty = computed(() => !formData.value[filterName])

  const urlKey = [urlPrefix, filterName].filter(Boolean).join('_')
  const bus = useEventBus([['filters', urlPrefix]].filter(Boolean).join('_'))
  const urlValues = useRouteQuery(getFilterUrlKey(filterName, urlPrefix), '', {
    transform: (v) =>
      typeof v === 'string' ? v.split(',').filter(Boolean) : [],
  })

  function persistInUrl(formData) {
    let value

    if (Array.isArray(formData[filterName]) && formData[filterName]?.length) {
      value = formData[filterName].join(',')
    } else if (formData[filterName]) {
      value = formData[filterName]
    }

    // Emit event with new value and reset values to bus in order
    // to update use-get-fetch-params composable
    bus.emit({
      [filterName]: value,
      ...resetFilters?.reduce((acc, key) => {
        acc[key] = undefined
        return acc
      }, {}),
    })

    // persist new values in the url
    router.replace({
      query: {
        ...route.query,
        ...resetFilters?.reduce((acc, key) => {
          acc[getFilterUrlKey(key, urlPrefix)] = undefined
          return acc
        }, {}),
        [urlKey]: value,
      },
      hash: route.hash ?? null,
    })
  }

  function setData(data) {
    formData.value = { [filterName]: data }
  }

  function resetForm() {
    persistInUrl({ [filterName]: null })
    isSubmitted.value = true
    isOpen.value = false
  }

  function submit() {
    submitForm(formId)
    isSubmitted.value = true
    isOpen.value = false
  }

  return {
    formId,
    formData,
    urlValues,
    persistInUrl,
    resetForm,
    isOpen,
    isSubmitted,
    isEmpty,
    setData,
    submitForm: submit,
  }
}
