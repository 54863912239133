<script setup lang="ts">
interface Props {
  option: {
    attrs: Object
    label: String
    thumbnail: Object
    value: string
  }
}

const { option } = defineProps<Props>()
const { icons } = useDesign()

const classes = computed(() => ({
  placeholder: 'w-full h-full rounded-md',
  image: 'w-full h-full rounded-md',
  container: 'aspect-[4/3] overflow-hidden rounded-md w-full',
}))

const imageObject = getImage(option, 'thumbnail', option.label as string)
const config = useAppConfig()
const imageConfig = computed(() => ({
  ...config.images.voucher.designOption,
}))

defineOptions({
  name: 'VoucherProductFormConfigureDesignOption',
})
</script>

<template>
  <ImageWrapper :image="imageObject" :config="imageConfig" :classes>
    <template #default="{ classList }">
      <ImagePlaceholder :icon="icons.GALLERY_IMAGE" :class="[...classList]" />
    </template>
  </ImageWrapper>
</template>
