<template>
  <slot :filter-options :pending />
</template>

<script lang="ts" setup>
export interface FiltersDataProviderShowGroupsFetchParams {
  movieId?: string
  cinemaIds?: string[]
  showGroups?: string[]
  cinemaProximity?: {
    city: string
  }
}

interface Props {
  urlPrefix?: string
  filters?: UrlFilter[]
  fetchParams: FiltersDataProviderShowGroupsFetchParams
}

const COMPONENT_NAME = 'FiltersDataProviderShowGroups'
const props = withDefaults(defineProps<Props>(), {
  filters: () => [],
  urlPrefix: '',
})

const { locale } = useI18n()

useGqlHeaders({ 'Accept-Language': locale.value })

const { data, execute, refresh, pending } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.urlPrefix),
  () =>
    GqlFetchShowGroupsFilters({
      ...props.fetchParams,
      filters: props.filters,
    }),
  {
    immediate: false,
  }
)

await execute()

watch(
  () => props.fetchParams,
  async () => {
    await refresh()
  }
)

const filterOptions = computed(
  () => data.value?.showGroups?.filterOptions ?? []
)

defineOptions({
  name: COMPONENT_NAME,
})
</script>
