import type { RouteLocationRaw } from 'vue-router'
import { MESSAGE_SEVERITY, MESSAGE_TYPE } from '@base/constants/message'
import { CART_STRINGS } from '@booking/constants/cart'
import type { Cart, CartType, Cinema } from '#gql/default'

const {
  UPDATE_CART_ERROR,
  ADDED_TO_CART,
  UPDATED_IN_CART,
  DELETED_FROM_CART,
  ITEMS_ADDED_TO_CART,
  ITEMS_REMOVED_FROM_CART,
} = CART_STRINGS

export default async function useCart() {
  const cart = useState<Cart | undefined>('cart')
  const cartId = useState<string | undefined>('cartId', () => undefined)
  const cartStringsLoaded = useState<boolean>('cartStringsLoaded', () => false)

  const { add: addMessage } = useMessage()
  const { ct, fetchCinemaStrings } = useCinemaStrings()

  const route = useRoute()
  const router = useRouter()
  const localeRoute = useLocaleRoute()

  const pending = ref(false)
  const expiresAt = computed(() => cart.value?.expiresAt ?? null)
  const availablePaymentMethods = computed(
    () => cart.value?.availablePaymentMethods ?? [],
  )

  useCountdown({
    expiresAt,
    onExpired: resetCart,
  })

  function displayError({
    message,
    type = MESSAGE_TYPE.TOAST,
  }: {
    message: string
    type?: MessageType
  }) {
    addMessage({
      message,
      severity: MESSAGE_SEVERITY.ERROR,
      type,
    })
  }

  async function fetchCart(cartId?: string) {
    if (cart.value || !cartId) {
      return cart.value
    }

    const result = await GqlFetchCart({ cartId })
    cart.value = result.cart as Cart

    if (!result?.cart) {
      resetCart()
    }

    return cart.value
  }

  async function setEmailContact({ email }: { email: string }) {
    const result = await GqlCartSetEmailContact({
      cartId: cart.value!.id,
      email,
    })

    if (!result?.cartSetEmailContact) {
      throw new Error('Failed to set email contact')
    }

    cart.value = result.cartSetEmailContact as Cart
    return result.cartSetEmailContact.contact.email
  }

  function resetCart() {
    cart.value = undefined
    cartId.value = undefined

    const query = {
      ...route.query,
    }

    // Remove query params that are no longer needed
    // For other shops this has to be extended to cleanup individual shop query params
    delete query.cartId
    delete query.step
    delete query.cartVoucherId
    delete query.cartVoucherDesignId

    // Add error to query params to trigger error modal
    query.error = 'NUXT_CART_RESET'
    query['error-desc'] = 'NUXT_CART_RESET_DESC'
    router.push({ query })
  }

  async function checkoutCart() {
    pending.value = true
    const result = await GqlCartCheckout({
      cartId: cart.value!.id,
    })

    if (!result?.cartCheckout) {
      pending.value = false
      throw new Error('Failed to checkout cart')
    }

    router.push(
      localeRoute({
        name: ROUTE_NAMES.ORDER,
        params: {
          orderNumber: result.cartCheckout.orderNumber,
          securityKey: result.cartCheckout.securityKey,
        },
      }) as RouteLocationRaw,
    )
  }

  async function ensureCartStrings({ cinema }: { cinema?: Cinema }) {
    if (cartStringsLoaded.value) {
      return
    }

    await fetchCinemaStrings({
      keys: [
        UPDATE_CART_ERROR,
        DELETED_FROM_CART,
        UPDATED_IN_CART,
        ADDED_TO_CART,
        ITEMS_ADDED_TO_CART,
        ITEMS_REMOVED_FROM_CART,
      ],
      cinemaId: cinema?.id,
    })

    cartStringsLoaded.value = true
  }

  async function ensureCart({
    cinema,
    type,
  }: {
    cinema?: Cinema
    type: CartType
  }): Promise<Cart> {
    if (!cart.value || !cartId.value) {
      const result = await GqlCartCreate({
        cinemaId: cinema?.id,
        type,
      })

      if (!result?.cartCreate) {
        throw new Error('Failed to create cart')
      }

      cartId.value = result.cartCreate.id
      cart.value = result.cartCreate as Cart

      // Add the cartId to the query params
      router.push({ query: { ...route.query, cartId: cartId.value } })
    }

    return cart.value
  }

  function getMessage({
    newQuantity,
    cartItemId,
    productName,
    previousQuantity,
  }: {
    newQuantity?: number
    cartItemId?: string | undefined
    productName?: string
    previousQuantity?: number
  } = {}): string {
    // Handle error
    if (!productName) {
      return ct(UPDATE_CART_ERROR)
    }

    // Handle deletion
    if (newQuantity === 0) {
      return ct(DELETED_FROM_CART, { item: productName })
    }

    // Handle quantity changes for existing items
    if (cartItemId && previousQuantity !== undefined && newQuantity) {
      const quantityDelta = newQuantity - previousQuantity

      // No quantity change, must be other updates (design, etc)
      if (quantityDelta === 0) {
        return ct(UPDATED_IN_CART, {
          formattedItem: formatQuantityAndName(newQuantity, productName),
        })
      }

      // Quantity increased
      if (quantityDelta > 0) {
        return ct(ITEMS_ADDED_TO_CART, {
          formattedItem: formatQuantityAndName(quantityDelta, productName),
        })
      }

      // Quantity decreased
      return ct(ITEMS_REMOVED_FROM_CART, {
        formattedItem: formatQuantityAndName(
          Math.abs(quantityDelta),
          productName,
        ),
      })
    }

    // Handle new items
    return ct(ADDED_TO_CART, {
      formattedItem: formatQuantityAndName(newQuantity, productName),
    })
  }

  return {
    cart,
    pending,
    ensureCart,
    ensureCartStrings,
    checkoutCart,
    setEmailContact,
    fetchCart,
    expiresAt,
    availablePaymentMethods,
    displayError,
    getMessage,
  }
}
