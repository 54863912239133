import useUserAuth from '@account/composables/use-user-auth'
import {
  BACKEND_TOKEN_QUERY_STRING,
  BACKEND_TOKEN_COOKIE_NAME,
} from '@account/constants/backend-token'

export default defineNuxtRouteMiddleware(async (to, _) => {
  const khUser = (to.query[BACKEND_TOKEN_QUERY_STRING.KHUSER] as string) ?? ''
  const khKey = (to.query[BACKEND_TOKEN_QUERY_STRING.KHKEY] as string) ?? ''
  const nuxtApp = useNuxtApp()
  const backendTokenCookie = useCookie(BACKEND_TOKEN_COOKIE_NAME, {
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000 * 15),
    path: '/',
  })
  const { isLoggedIn, logout: logoutFrontendUser } = useUserAuth()
  const { fetchToken, backendToken } = useBackendToken()

  // Server-side cookie check
  if (nuxtApp.payload.serverRendered) {
    if (backendTokenCookie.value) {
      backendToken.value = backendTokenCookie.value
    } else if (khUser && khKey && !backendToken.value) {
      await fetchToken({
        [BACKEND_TOKEN_QUERY_STRING.KHUSER]: khUser,
        [BACKEND_TOKEN_QUERY_STRING.KHKEY]: khKey,
      })
    }

    // Logout if user is logged in and has a backend token
    if (backendToken.value && isLoggedIn.value) {
      logoutFrontendUser()
    }
  }

  if (import.meta.client && backendToken.value) {
    backendTokenCookie.value = backendToken.value

    if (khUser || khKey) {
      return navigateTo({
        path: to.path,
        query: {
          ...to.query,
          khuser: undefined,
          khkey: undefined,
        },
      })
    }
  }
})
